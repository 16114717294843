<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>State List</h4>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button>
      </div>
      <b-table
        :key="tableKey"
        responsive="sm"
        :items="states"
        :fields="fields"
        @row-clicked="showDetails"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Edit' : 'Add New'"
      hide-footer
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="stateForm">
          <b-form @submit.prevent="is_update ? updateState() : createState()">
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Name"
                  label-for="v-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="state.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group
                  label="Abbriviation"
                  label-for="v-abbreviation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="abbreviation"
                    rules="required"
                  >
                    <b-form-input
                      id="v-abbreviation"
                      v-model="state.abbreviation"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Abbriviation"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
                :disabled="is_edited === false && is_update ? true : false"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      fields: [{ key: 'id', label: '#ID' }, { key: 'name', label: 'name' }, { key: 'abbreviation', label: 'abbreviation' }],
      state: {
        name: '',
        abbreviation: '',
      },
      required,
      is_edited: false,
      selected_data: null,
      tableKey: 0,
    }
  },
  computed: {
    states() {
      return this.$store.state.state.states
    },
  },
  watch: {
    state: {
      handler(oldValue, newValue) {
        if (newValue && this.is_update) {
          this.is_edited = true
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    getState() {
      return this.$store.dispatch('state/getStates')
    },
    createState() {
      this.$refs.stateForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('state/createState', this.state)
          this.hide()
        }
      })
    },
    updateState() {
      this.$refs.stateForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('state/updateState', this.state)
          this.is_edited = false
          this.hide()
        }
      })
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('state/deleteState', this.state.id)
          this.is_edited = false
          this.hide()
        }
      })
    },
    emptyData() {
      const dom = this
      setTimeout(() => {
        dom.state = {
          name: '',
          abbreviation: '',
        }
      }, 500)
    },
    showModal() {
      this.is_update = false
      this.$bvModal.show('app-modal')
    },
    hide(e) {
      if (this.is_edited === true) {
        e.preventDefault()
        this.$swal({
          title: 'Do you want to save changes',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Save Changes',
          cancelButtonText: 'Discard Changes',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updateState()
            this.is_edited = false
          } else if (result.dismiss === 'cancel') {
            this.is_update = false
            this.is_edited = false
            this.$bvModal.hide('app-modal')
            this.getState()
            this.emptyData()
          }
        })
      } else {
        this.is_update = false
        this.$bvModal.hide('app-modal')
        this.is_edited = false
        this.emptyData()
      }
    },
    showDetails(item) {
      this.is_update = true
      this.state = item
      this.$bvModal.show('app-modal')
      const dom = this
      setTimeout(() => {
        dom.is_edited = false
      }, 1000)
    },
  },
}
</script>

  <style>

  </style>
